import { renderClasses } from 'Shared/Helper/Bem/Bem';
import { findOne, findAncestor, offsetRelativeToAncestor, animatedScroll } from 'Shared/Helper/Dom/Dom';
import { easeInOutQuad } from 'Shared/Helper/Easing/Easing';
import { displayLightBox } from 'Shared/Helper/Lightbox/LightboxManager';

const NAME = 'listing-detail-controls';

const CLASSES = {
    'options': renderClasses(NAME, 'options'),
    'optionsActive': renderClasses(NAME, 'options', ['active']),
    'elementExpandable': renderClasses(NAME, 'element', ['expandable']),
};

const SELECTORS = {
    'options': `.${CLASSES.options}`,
    'elementExpandable': `.${CLASSES.elementExpandable}`,
};

const ATTRS = {
    'ariaHidden': 'aria-hidden',
};

function listingDetailControlsControllerFactory (dependencies) {
    return class ListingDetailControls extends dependencies.componentController {
        connect () {
            super.connect();

            this.openPanels = [];

            this.setCancellationEventHandlers();
        }

        onPhotosClick () {
            const url = this.data.get('photos-lightbox-path');

            displayLightBox(url);
        }

        onVideosClick () {
            const url = this.data.get('videos-lightbox-path');

            displayLightBox(url);
        }

        onGroundPlansClick () {
            const url = this.data.get('ground-plans-lightbox-path');

            displayLightBox(url);
        }

        on360PhotosClick () {
            const url = this.data.get('360-photos-lightbox-path');

            displayLightBox(url);
        }

        onToggleClick (evt) {
            const expandableElement = findAncestor(SELECTORS.elementExpandable, evt.target);

            if (this.openPanels.includes(expandableElement)) {
                this.closePanel(expandableElement);
            } else {
                this.openPanel(expandableElement);
            }
        }

        onLocationClick (evt) {
            const href = evt.currentTarget.href;
            const elementId = href.split('#')[1];
            const mapNode = document.getElementById(elementId);
            const scrollOffset = offsetRelativeToAncestor(mapNode, document.body);

            this.messageBus.postMessage({
                'message': 'showMapClicked',
                'data': {},
            });

            animatedScroll(window, scrollOffset.top, {
                'duration': 1000,
                'easingFunction': easeInOutQuad,
            });

            evt.preventDefault();
        }

        openPanel (expandableElement) {
            const options = findOne(SELECTORS.options, expandableElement);

            options.classList.add(CLASSES.optionsActive);
            options.setAttribute(ATTRS.ariaHidden, 'false');

            this.openPanels.push(expandableElement);
        }

        closePanel (expandableElement) {
            const options = findOne(SELECTORS.options, expandableElement);

            options.classList.remove(CLASSES.optionsActive);
            options.setAttribute(ATTRS.ariaHidden, 'true');

            this.openPanels = this.openPanels.filter(panel => panel !== expandableElement);
        }

        closeAllPanels () {
            this.openPanels.forEach(expandableElement => this.closePanel(expandableElement));
        }

        setCancellationEventHandlers () {
            document.addEventListener('keyup', (evt) => {
                if (evt.key == 'Escape') {
                    this.closeAllPanels();
                }
            });

            document.addEventListener('click', (evt) => {
                const clickedElm = evt.target;
                const expandableElement = findAncestor(SELECTORS.elementExpandable, clickedElm);

                if (!expandableElement) {
                    this.closeAllPanels();
                }
            });
        }

        get componentName () {
            return NAME;
        }
    };
}

export { listingDetailControlsControllerFactory };
